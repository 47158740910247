<template>
  <div>
    <a-card style="background-color: #fff;">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="分销商" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.code" style="width: 100%" placeholder="请选择分销商"
                            option-label-prop="label">
                    <a-select-option value="20220601001" label="优加惠品">
                      优加惠品
                    </a-select-option>
                    <a-select-option value="20221215001" label="好乐滋">
                      好乐滋
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

           
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="当前客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.staffIdList" style="width: 100%" mode="multiple" placeholder="请选择当前客户经理" :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="出库客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.salespersonIdList" style="width: 100%" mode="multiple"
                            placeholder="请选择出库客户经理" :default-value="null" :filter-option="filterOption"
                            option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.buyCustomerId" show-search
                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                            style="width: 100%" placeholder="请选择客户"
                            :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-range-picker :value="dateValue" :ranges="{
                                        '今天': [moment(), moment()],
                                        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                       '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }" @change="dateChange"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="下单时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-range-picker :value="dateValueOrderTime" :ranges="{
                                        '今天': [moment(), moment()],
                                        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                       '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }" @change="OrderTimeChange"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                          @click="search">查询
                </a-button>

              </a-col>
            </a-row>

            <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项
            </div>
          </div>

        </a-form>

      </div>


      <div>
        <p class="spin-content">{{totalText}}</p>
        <a-table :columns="columns"  :dataSource="dataSource" bordered :loading="tableLoading" :pagination="false"   :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="orderTime" slot-scope="text">{{ formatDate(text) }}</template>

          <template slot="type" slot-scope="text">
            <span v-if="text == 20">货堆</span>
            <span v-if="text == 21">宅配</span>
          </template>
          <template slot="status" slot-scope="text">
            <span v-if="text == 1">正常</span>
            <span v-if="text == 2">取消</span>
          </template>

          <!--  税费 -->
          <template slot="outCardTaxList" slot-scope="text">
            <div>
              <div v-if="text">
                <div v-for="(item,index) in text" :key="item">
                  <p>{{item.amount}} * {{ text[index].taxRate }}%</p>
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </template>
          <!-- 提成 -->
          <template slot="outCardCom" slot-scope="text">
            <span>{{ text.amount }}</span>
          </template>
          <!-- M+ -->
          <template slot="outCardOxp" slot-scope="text">
            <span>{{ text.amount }}</span>
          </template>

          
        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer/>
        </div>


      </div>

    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from '@/utils/request'
import {publicMixin} from '@/mixin/public'

const columns = [
  {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80,},
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
    width: 200,
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    scopedSlots: {customRender: 'orderTime'},
    width: 200,
    align: 'center'
  },
  {
    title: '订单类型',
    dataIndex: 'type',
    scopedSlots: {customRender: 'type'},
    width: 100,
    align: 'center'
  },
  {
    title: '分销商名称',
    dataIndex: 'distributorName',
    width: 120,
    align: 'center'
  },
  {
    title: '销售订单号',
    dataIndex: 'code',
    width: 200,
    align: 'center'
  },
  {
    title: '客户名称',
    dataIndex: 'buyCustomerName',
    width: 150,
    align: 'center'
  },
  {
    title: '出库客户经理',
    dataIndex: 'salespersonName',
    width: 150,
    align: 'center'
  },
  {
    title: '当前客户经理',
    dataIndex: 'customerStaffName',
    width: 150,
    align: 'center'
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    width: 150,
    scopedSlots: {customRender: 'status'},
    align: 'center'
  },
  {
    title: '商品总数量',
    dataIndex: 'quantity',
    width: 150,
    align: 'center'
  },
  {
    title: '平台总价格',
    dataIndex: 'plfPrice',
    width: 150,
    // 合计
    align: 'center'
  },
  // {
  //     title: '销售总价格',
  //     dataIndex: 'salPrice',
  //     align: 'center'
  // },
  {
    title: '回款金额',
    dataIndex: 'quota',
    width: 150,
    align: 'center',
    customRender: (text) => {
      return text || '-'; // 如果值存在则返回值，否则返回 '-'
    }
  },
  {
    title: '是否需要开票',
    dataIndex: 'invoiceName',
    width: 150,
    align: 'center',
    customRender: (text) => {
      return text || '-'; // 如果值存在则返回值，否则返回 '-'
    }
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatusName',
    width: 150,
    align: 'center',
    customRender: (text) => {
      return text || '-'; // 如果值存在则返回值，否则返回 '-'
    }
  },
  {
    title: '税费',
    dataIndex: 'outCardTaxList',
    width: 150,
    align: 'center',
    scopedSlots: {customRender: 'outCardTaxList'},
  },
  {
    title: '提成',
    dataIndex: 'outCardCom',
    width: 150,
    align: 'center',
    scopedSlots: {customRender: 'outCardCom'},
  },
  {
    title: 'M+',
    dataIndex: 'outCardOxp',
    width: 150,
    align: 'center',
    scopedSlots: {customRender: 'outCardOxp'},
  },
  // {
  //     title: '成本总价格',
  //     dataIndex: 'price',
  //     align: 'center'
  // },
  {
    title: '总运费',
    dataIndex: 'freight',
    width: 150,
    align: 'center',
    // customRender: (text) => {
    //   return text || '-'; // 如果值存在则返回值，否则返回 '-'
    // }
  },
  {
    title: '总包材',
    dataIndex: 'packPrice',
    width: 150,
    align: 'center',
    // customRender: (text) => {
    //   return text || '-'; // 如果值存在则返回值，否则返回 '-'
    // }
  },
]

export default {
  name: 'QueryList',
  mixin: [publicMixin],
  data() {
    return {
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
      totalText:"",
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        type:20
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      currentPage: 1,
      total: 0,
      brandList: [],
      pdtCatList: [],
      dateValue: "",
      dateValueOrderTime:""
    }
  },
  mounted() {
    // 列表
    this.getData();
    // 销售
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/list/1', 'get').then(res => {
      this.userData = res.data.data
    })
    // 客户列表
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
      this.customerData = res.data.data
    })
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    OrderTimeChange(date, dateString){
      this.queryData.dateOrderMin = dateString[0].toString();
      this.queryData.dateOrderMax = dateString[1].toString();
      this.dateValueOrderTime = date;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    pdtChange(e) {
      delete this.queryData.scmBrandId
      this.getBrand(e)
    },
    getBrand(id) {
      let url = 'sfa/scm/brand/list/normal'
      request(process.env.VUE_APP_API_BASE_URL + url, 'post', {pdtCatId: id}).then(res => {
        if (res.data.code == 200) {
          this.brandList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    getNormalpdtcat() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/brand/list/normalpdtcat', 'get').then(res => {
        if (res.data.code == 200) {
          this.pdtCatList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    search(){
      this.queryData.page = 0;
      this.getData()
    },
    // 获取列表
    getData() {
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/order/ka/list', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/order/ka/list/count', 'post', {type:20}).then(res => {
        this.totalText = res.data.data;
      })
    },
    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData()
    },
    exportTask() {
      this.$message.loading('处理中')
      let params = this.queryData;
      params.remarks = ''
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      // const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      return result
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
        type:20
      }
      this.dateValue = ''
      this.dateValueOrderTime = ""
      this.getData()
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.spin-content {
  color: #5542F6;
  margin-top: 10px;
}
.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}
</style>
  